import { Content } from "@/ui";
import { simple_pages_helper } from "@/helpers/simple_pages_helper";
import { sector_helper } from "@/helpers/sector_helper";
import { getNestedRoutes } from "@/functions/getNestedRoutes";
import { getSectorStaticProps } from "@/functions/getSectorStaticProps";
import { getGlobal } from "@/getters/getGlobal";
import { getSimplePagesStaticProps } from "@/functions/getSimplePagesStaticProps";
import useDynamicPage from "@/hooks/useDynamicPage";
import { getDynamicPageStaticProps } from "@/functions/getDynamicPageStaticProps";
import { DynamicPageSeo } from "@/ui/DynamicPageSeo";
import { ContentBlocks } from "@/ui/ContentBlocks";
import useDynamicPages from "@/hooks/useDynamicPages";

/**
 *
 * @param content
 * @param prefix
 * @param title
 * @param seo
 * @param canonical
 * @param data
 * @param isBuilder
 * @returns {JSX.Element}
 * @constructor
 */
export default function Page({ content, prefix, title, seo, canonical, data, isBuilder = false }) {
  return (
    <>
      {isBuilder ? (
        <>
          <DynamicPageSeo
            {...seo}
            title={seo?.title.length > 0 ? seo.title : title}
            canonical={seo?.canonical.length > 0 ? seo.canonical : canonical}
          />
          <ContentBlocks data={data} prefix={prefix} />
        </>
      ) : (
        <Content items={content} logoCarousel={false} />
      )}
    </>
  );
}

/**
 *
 * @param url_slugs
 * @returns {Promise<{props: {meta: {title: string, canonical: string}, content: [{component: string, props: {items: [{label: *, href: *|string}]}},{component: string, props: {className: string, title, description: {path: string}, img, variant: string, back: {path: string, placeholder: string, href: *|string}}},{component: string, id: string, props: {sector: *, changeURLOnFilterChange: boolean}},{component: string, props: {className: string, containerClassName: null, body}}]}}|{notFound: boolean}|{props: {isBuilder: boolean, prefix: string, title: *, seo: *, canonical: string, data: *}}>}
 */
export async function getStaticProps({ params: { url_slugs } }) {
  const url_slug = url_slugs.join("/");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const builderPage = useDynamicPage(url_slug);
  if (builderPage) {
    return getDynamicPageStaticProps({ page: builderPage, url_slug });
  }

  const pages = getNestedRoutes({
    url_slugs,
    overwrites: { "recruitment-solutions": { href: "/recruitment-solutions/why-choose-blue-arrow/" } },
  });
  const [_page, prevPage] = [...pages].reverse();
  const page = simple_pages_helper.nestedFind(url_slugs);
  const sector = sector_helper.find(_page.url_slug);

  if (sector) {
    return getSectorStaticProps({
      sector_id: sector.id,
      pages: page ? simple_pages_helper.fetch({ parent: page.id }) : null,
    });
  }

  return getSimplePagesStaticProps({ pages, page, prevPage });
}

/**
 *
 * @returns {Promise<{paths: *[], fallback: boolean}>}
 */
export async function getStaticPaths() {
  const global = getGlobal();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const builderPaths = useDynamicPages()
    .filter((i) => !["/"].includes(i.url_slug))
    .map((i) => ({ params: { url_slugs: i.url_slug.split("/") } }));

  return {
    paths: [
      ...builderPaths,
      ...simple_pages_helper.toNestedPaths({
        exclude: [
          ...global["_theme.simplePagesExcludeList"].split(",").map((i) => i.trim()),
          ...[
            "674340eb-6d6e-43d0-9db0-f72ed478b1c6", // "care-home-staffing",
            "674e4d9b-fbce-4dce-ae48-461acba2f4de", // "productivity-solution",
            "20f32f96-c213-4fdf-a830-fd81f0bb48bf", // "public-sector",
            "fac7e1fb-f81c-4289-b861-4d5f19d2ebe0", // "vms-lite",
            "6dfaa9f0-3836-4349-8966-dd3aa726a46c", // "fulfilment-plus",
            "17b927dc-594d-4c2c-acaa-9dbf9cda621d", // "recruiterbot",
            "d20e9d98-07a5-4b09-aabe-c030ce7b7e91", // "client-portal"
            "04674535-a5f2-43e5-a747-318655cfd4e5", // "managed-service-provider"
            "39f3d04d-b1f9-466d-a047-ea2e82e2bf21", // "non-classroom-staffing-education-providers"
            "a4909408-5e07-4a30-a018-e3b6bc4452a7", // "contact-centre-staffing"
            "77a84abe-f14c-4a1a-8523-f8af39a42b76", // "university-staffing"
            "d41079dd-2e6b-46bf-9c8b-fa992adfb488", // "hgv-driver-training"
            "a30f606d-8794-4219-a91c-6854be35f827", // "catering-and-hospitality-staffing"
            "8ec17a19-38c5-48d6-9be1-1ba3bf36e583", // "driving-and-distribution-staffing"
            "4372d1da-7eee-479e-9043-5c1ccdbff474", // "industrial-logistics-and-warehouse-staffing"
            "c7ad303e-d232-4a85-bff0-8fbc9e19fa9d", // "manufacturing"
            "23055660-e3fd-4a3a-a6c2-bbba90053641", // "retail"
            "9a555b95-e052-4024-bd95-876327410487", // "healthcare-and-nhs"
            "01086bd3-2f4b-48f5-b22d-4f435c992b5f", // temporary-staffing-solutions
            "60630b11-12c7-4cf6-a5a9-d91ebe757d65", // permanent-staffing-solutions
            "6c12d14e-00af-4d5a-bc30-105acfdca4ba", // what-is-our-permanent-staffing-recruitment-process
            "00f82c80-52b4-4572-a292-02305226bb9a", // what-is-our-temporary-staffing-recruitment-process
          ],
          ...simple_pages_helper.fetch({ parent: "3ac0b752-94d4-49ee-b17a-c45aac2c5eda" }).map((i) => i.id),
        ],
      }),
      ...sector_helper.toNestedPaths({
        exclude: global["_theme.sectorPagesExcludeList"].split(",").map((i) => i.trim()),
      }),
    ],
    fallback: false,
  };
}
